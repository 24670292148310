import React from "react"
import Layout from "../layouts/default"

const pageData = {
  title: "Ou nou!",
  description: "Etsimäsi sivu on kadonnut bittiavaruuteen.",
}

const NotFoundPage = () => (
  <Layout pageData={pageData}>
    <h1>{pageData.title}</h1>
    <p>{pageData.description}</p>
  </Layout>
)

export default NotFoundPage
